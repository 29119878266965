import React from 'react';
import { billingImage } from './image-data';

interface Props {
  vocab: string;
}

const Billing = ({ vocab }: Props) => {
  return (
    <div>
      <img src={billingImage} alt={`billing-${vocab}`} />
    </div>
  );
};

export default Billing;
