import React from 'react';
import { realestateImage } from './image-data';

interface Props {
  vocab: string;
}

const Realestate = ({ vocab }: Props) => {
  return (
    <div>
      <img src={realestateImage} alt={`billing-${vocab}`} />
    </div>
  );
};

export default Realestate;
